// import { NextPageContext } from "next";
// import { Auth, Hub } from "aws-amplify";
// import {
// 	configureSSRAmplifyOnServer,
// 	getServerClinicDomain,
// 	getTenantCredentials,
// 	setAuthCookies,
// } from "../helpers/server";
// import axios from "axios";
// import { setTokensAndAuthenticate } from "../helpers/utils";
// import { useLayoutEffect, useState } from "react";
// import { useAuth } from "../hooks/auth";
// import { useDispatch } from "react-redux";
// import { code, updateRememberMe } from "../redux/user";
// import { useRouter } from "next/router";
// import CustomLoader from "../components/CustomLoader";
import { useTranslation } from "react-i18next";
import { ecoSystemAuthTokens } from "../helpers/types";
import Authenticated from "../components/templates/Authenticated/AppLayout";
import { Box } from "@mui/material";

export default function Home(props: {
	isAuthenticated: boolean;
	tokens: ecoSystemAuthTokens;
	rememberMe: string;
	loggedTime: string;
}) {
	const { t } = useTranslation();
	// const [loading, setLoading] = useState(true);
	// const { isAuthenticated, tokens, rememberMe, loggedTime } = props;
	// const dispatch = useDispatch();
	// const { logoutRouteHandler } = useAuth();
	// const router = useRouter();

	// const { code: codeValue } = router.query;

	// const ssoLogin = () => {
	// 	if (isAuthenticated) {
	// 		const isTokensValid = setTokensAndAuthenticate(tokens);
	// 		if (isTokensValid) {
	// 			dispatch(
	// 				updateRememberMe({
	// 					rememberMe: rememberMe,
	// 					loggedTime: loggedTime,
	// 				}),
	// 			);
	// 			dispatch(code(codeValue));
	// 			Auth.currentAuthenticatedUser().then((data) => {
	// 				Hub.dispatch("auth", {
	// 					event: "signIn",
	// 					data,
	// 				});
	// 			});
	// 			setLoading(false);
	// 		} else {
	// 			logoutRouteHandler();
	// 			setLoading(false);
	// 		}
	// 	} else {
	// 		logoutRouteHandler();
	// 		setLoading(false);
	// 	}
	// };

	// useLayoutEffect(() => {
	// 	ssoLogin();
	// }, []);

	// if (loading) {
	// 	return <CustomLoader open={loading} />;
	// }
	return (
		<>
			<Authenticated>
				<Box sx={{ height: "calc(100vh - 70px)" }}></Box>
			</Authenticated>
		</>
	);
}
// export async function getServerSideProps(context: NextPageContext) {
// 	const response = {
// 		props: {
// 			isAuthenticated: false,
// 			tokens: null,
// 			rememberMe: "",
// 			loggedTime: "",
// 		},
// 	};

// 	try {
// 		const { req, query, res } = context;
// 		const hostname = req?.headers.host || "";
// 		// const cookies = req?.headers.cookie || "";
// 		const tenant = getServerClinicDomain(hostname);
// 		const appId = getTenantCredentials(tenant, "AWS_USERPOOL_APP_ID");
// 		const SSR = configureSSRAmplifyOnServer(context, tenant);
// 		const code = query.code || "";

// 		let user = null;
// 		if (code) {
// 			const { data } = await axios.get(
// 				`${getTenantCredentials(
// 					tenant,
// 					"SSO_URL",
// 				)}get-cognito-tokens/${code}`,
// 			);
// 			if (data.length) {
// 				const tokens = JSON.parse(data[0].tokens.S);
// 				const id = data[0].id.S;
// 				const rememberMe = data[0].is_remember_me.S;
// 				const loggedTime = data[0].last_login_at.S;

// 				response.props.tokens = {
// 					...tokens,
// 					sub: id,
// 				};
// 				response.props.rememberMe = rememberMe;
// 				response.props.loggedTime = loggedTime;
// 				setAuthCookies(res, appId, id, tokens);

// 				user = await SSR.Auth.currentAuthenticatedUser().catch(
// 					() => null,
// 				);
// 				console.log("user", user);
// 				if (user) {
// 					response.props.isAuthenticated = true;
// 				}
// 			}
// 		}
// 		return {
// 			...response,

// 			...(!user &&
// 				code && {
// 					redirect: {
// 						destination: req?.url,
// 						permanent: false,
// 					},
// 				}),
// 		};
// 	} catch (error) {
// 		console.log("error", error);
// 		return response;
// 	}
// }
